.sidebar-icon {
    font-size: 20px;
    min-width: 60px;
    display: flex!important;
    align-items: center;
    justify-content: center;
}

.sidebar-text {
    font-weight: 600;
    
}
.ps-submenu-content .sidebar-text {
    margin-left:1rem!important;
}
.sidebar-div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 6px;
    transition: all 0.3s ease;
}