@import "node_modules/bootstrap/scss/bootstrap";

.table {
  --bs-table-bg: #fff !important;
   th, td {
     padding: 1rem; // example customization
     color: #000;
  }
}

.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}
.dataTables_wrapper .dataTables_paginate .paginate_button.active {
  background: $primary-color;
  border-color: $primary-color;
}
.form-group-radio {
  padding: 10px;
}
.add-question-choice {
  border-radius: 3px !important;
  padding: 10px !important;
  margin-bottom: 10px !important;
  border: "1px solid #D3D3D3" !important;
}
.col-select-test.active {
  text-align: center;
  border: 2px dotted #c2c2c2;
  background-color: #cfaf6f;
}
.col-select-test.active label {
  color: #fff;
}
.col-select-test.active i {
  color: #fff;
}
.col-select-test {
  text-align: center;
  border: 2px dotted #c2c2c2;
  background-color: #fff;
  margin: 5px;
  padding: 10px;
}

.dzu-previewImage {
  width: auto;
  max-height: 210px;
  max-width: auto;
  border-radius: 4px;
}
#progress {
  vertical-align: baseline;
  display: none;
}
@media screen and (min-width: 1367px) {
}
@media screen and (min-width: 768px) and (max-width: 1023) {
}
@media screen and (max-width: 767px) {
  .form-group-radio {
    padding: 0px;
  }
  .group-col-6 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .add-question-choice {
    border: "1px solid #D3D3D3" !important;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
}
.f-circle {
  font-family: Arial;
  font-weight: bold;
  display: block;
  background-color: blue;
  border: 1px solid #fff;
  border-radius: 999px;
  padding: 6px 12px;
  width: 35px;
  height: 35px;
  box-shadow: rgb(153, 153, 153) 0px 0px 10px;
}
.fa-alph {
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: #fff;
  font-style: normal;
}
.skill-level-0 {
  background: #f2f2f2;
  padding: 5px;
  margin: 5px;
  border-radius: 2px;
  border: 1px solid #d3d3d3;
}

.rotate {
  animation: rotation 0.5s infinite linear;
  width: 60px;
  height: 60px;
  padding: 5px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.btn-success {
  color: #fff !important;
  background: #41c44a !important;
  border-color: #41c44a !important;
}

.btn-success:hover {
  color: #fff !important;
  background: #41c44a !important;
  border-color: #41c44a !important;
}
.input-success input {
  background: #41c44a;
  border-color: #41c44a;
}
label.btn-primary {
  color: #000;
  background: #f2f2f2;
  border-color: #f2f2f2;
}
.auth-wrapper b {
  color: #41c44a;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background: #41c44a !important;
}

.center-data th {
  text-align: center;
}
.center-data td {
  text-align: center;
}

table.dataTable thead .sorting_asc {
  background-image: none !important;
  color: #fff;
}
table.dataTable thead .sorting_desc {
  background-image: none !important;
}
@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: right;
  }
  .navbar-light .navbar-nav .nav-link {
    // color: rgb(36, 101, 134) !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  // .bg-primary {
  //   background-color: #f2f2f2 !important;
  //   color: rgb(36, 101, 134);
  // }
}
@media screen and (max-width: 992px) {
  // .activenav {
  //   color: #fff !important;
  // }
  .navbar-light .navbar-nav .nav-link {
    // color: rgb(36, 101, 134);
    padding-left: 20px;
    padding-right: 20px;
  }

  // .bg-primary {
  //   background-color: #f2f2f2 !important;
  //   color: rgb(36, 101, 134);
  // }
  .nav {
    // background-color: #f2f2f2 !important;
    display: list-item;
  }
  // .navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  //   color: rgb(36, 101, 134) !important;
  //   border:1px solid rgb(36, 101, 134) !important;
  //   border-radius: 5px;
  // }

  table.dataTable th {
    text-align: left !important;
  }
  table.dataTable tr {
    text-align: left !important;
  }
}

.table.dataTable[class*="table-"] thead th {
  background: #dddddc;
  // background: #eaeaea;
  // color: #000;
  color: #000;
}

table.dataTable th {
  text-align: center;
}
table.dataTable tr {
  text-align: center;
}

.pcoded-navbar.theme-horizontal ~ .pcoded-main-container {
  margin-top: 0px;
}
.pcoded-main-container {
  padding-top: 0 !important;
}
.pcoded-navbar.menu-light {
  display: none;
}
.pcoded-main-container {
  padding-top: 50px;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff !important;
}
.navbar-light .navbar-brand {
  background: #f2f2f2;
  padding-left: 10px;
  padding-right: 10px;
}
.fixed-top {
  position: unset;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.navbar-light .navbar-toggler {
  margin-right: 10px;
}

.nav-item label {
  display: none;
}
.nav {
  // background-color: $primary-color;
}
.nav-links {
  color: #fff !important;
}

.activenav {
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.activenav-primary {
  background: #216f7d;
  color: #fff !important;
  border: 1px solid #216f7d;
}
.activenav-secondary {
  background: #0927d3;
  color: #fff !important;
  border: 1px solid #0927d3;
}
.activenav-danger {
  background: #6237a0;
  color: #fff !important;
  border: 1px solid #6237a0;
}
.activenav-warning {
  background: #0b5394;
  color: #fff !important;
  border: 1px solid #0b5394;
}
.activenav-info {
  background: #853e61;
  color: #fff !important;
  border: 1px solid #853e61;
}
.activenav-success {
  background: #a32121;
  color: #fff !important;
  border: 1px solid #a32121;
}
.pagination-block {
  padding-top: 10px;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

.fileUploader {
  border: 1px solid #e1e1e1 !important;
}

.fileContainer {
  background: #fff !important;
  box-shadow: none !important;
  position: relative !important;
  border-radius: 10px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  transition: all 0.3s ease-in !important;
  height: 275px !important;
}

.fileContainer p:empty {
  font-size: 12px !important;
  margin: 0 !important;
}

.fileContainer .uploadPicturesWrapper {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  position: relative !important;
  top: -57px !important;
  width: 372px !important;
  height: auto !important;
}
.uploadPicturesWrapper > div:empty {
  height: 1px !important;
}
.uploadPicturesWrapper > div {
  height: 255px !important;
}
.fileContainer .uploadPictureContainer {
  width: 95% !important;
  height: 90% !important;
  // margin: 5px !important;
  padding: 0 !important;
  background: #edf2f6 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* height: inherit; *!important/
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  /* border: 1px solid #d0dbe4; */
  position: relative !important;
}
// .circular--portrait {
//   position: relative;
//   width: 20vw;
//   height: 20vw;
//   overflow: hidden;
//   border-radius: 50%;
// }
.uploadPictureContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fileContainer .deleteImage {
  position: absolute !important;
  right: unset !important;
  top: unset !important;
  border-radius: 0 !important;
  text-align: center !important;
  cursor: pointer !important;
  font-size: 26px !important;
  font-weight: bold !important;
  line-height: 30px !important;
  width: 134px !important;
  height: 30px !important;
  left: 0 !important;
  bottom: -45px !important;
  background-image: url(https://cdn.onlinewebfonts.com/svg/img_96165.png) !important;
  background-position: left 4px top 3px !important;
  background-repeat: no-repeat !important;
  background-size: 17px 23px !important;
  background-color: transparent !important;
  color: black !important;
  overflow: hidden !important;
  content: "" !important;
  // text-indent: -156px!important;
  text-align-last: justify;
  font-size: 1px !important;
  margin-left: 90px;
}

.fileContainer .deleteImage:after {
  content: "Delete image" !important;
  position: absolute !important;
  right: 0px;
  font-size: 16px !important;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
  // width: 285px!important;
  // height: 235px!important;
}

.fileContainer .errorsContainer {
  max-width: 300px;
  font-size: 12px !important;
  position: absolute !important;
  z-index: 10 !important;
  top: 35% !important;
  background: rgba(255, 0, 0, 0.7) !important;
  padding: 10px !important;
  text-align: center !important;
  color: white !important;
  margin: 0 auto !important;
  width: 79% !important;
  border-radius: 6px !important;
}

.fileContainer .errorsContainer:empty {
  opacity: 0;
}

.fileContainer .chooseFileButton {
  padding: 6px 23px !important;
  // background-image: url(../../images/uploadlogo.png)!important;
  background-color: #c1c1c1 !important;
  background-repeat: no-repeat !important;
  background-position: 50% 23% !important;
  background-size: 25% !important;
  border-radius: 0 !important;
  color: white !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  margin: 0px 0 !important;
  transition: all 0.2s ease-in !important;
  cursor: pointer !important;
  outline: none !important;
  border: none !important;
  width: 95% !important;
  height: 100% !important;
}

.fileContainer .chooseFileButton:hover {
  // background-image: url(../../images/uploadlogo.png)!important;
  background-color: #c1c1c1 !important;
  background-repeat: no-repeat !important;
  background-position: 50% 23% !important;
  background-size: 25% !important;
}

.fileContainer .deleteImage {
  content: none;
}

/* 
.fileUploader {
  border: 1px solid #e1e1e1;
}

.fileUploader .fileContainer {
  display: flex;
  flex-direction: row-reverse;
  padding:10px 10px;
  box-shadow: none;
  margin: 0 0 0 0;
}

.fileUploader .fileContainer .uploadPicturesWrapper {
  border: 1px solid #e1e1e1;
  order: 1;
  min-height: 200px;
  margin-right: 15px;
} */
.user-card p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 12; /* number of lines to show */
  line-clamp: 12;
  -webkit-box-orient: vertical;
  margin-top: 1rem;
}

.user-card button {
  margin: 5px;
}

tr th:nth-child(2) {
  text-align: left;
}

tr td:nth-child(2) {
  text-align: left;
}

.select__control:hover {
  border-color: rgb(36, 101, 134) !important;
}

.text-limit {
  margin-bottom: 0px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 180px !important;
}
// ------ribbon------------

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 8;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #246586;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 7px 0;
  background-color: $primary-color;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 10px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
  margin-left: 54px;
}
.ribbon-top-right::after {
  bottom: 0;
  right: -54px;
  margin: 53px;
}
.ribbon-top-right span {
  left: 0px;
  top: 30px;
  transform: rotate(45deg);
}
.bg-progress {
  background-color: #bccaa5 !important;
  color: black !important;
}
.progress {
  font-size: 12px;
}
.bg-pending {
  background-color: #d9d9d9 !important;
  color: black !important;
}
// ------ribbon------------
// -------select-test--------
// -------select-test--------
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -12px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5bc44b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5bc44b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.form-inline label {
  display: inline-block;
  margin-bottom: -5px;
  margin-right: 10px;
}
i {
  cursor: pointer;
}

label.warning-label {
  color: red;
}

.nestable {
  display: block;
  clear: both;
  padding: 5px 0;
  width: 100%;
  border: 0;
  // border-top: 2px solid #ddd;
  // border-bottom: 2px solid #ddd;

  .nestable-item,
  .nestable-item-copy {
    margin: 5px 0;
    .nestable-list {
      margin: 5px 0;
    }

    &.is-dragging:before {
      background: #f2fbff;
    }
  }

  .pc-nestable-item {
    position: relative;
    padding: 5px 10px;
    font-size: 13px;
    border: 1px solid #ccc;
    background: #fff;
    color: #333;
    cursor: pointer;
    font-weight: 700;
    border-radius: 3px;

    .nestable-item-handler {
      margin-left: 30px;
      content: "\00A7";
    }
  }

  .pc-nestable-item-handler {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    text-indent: 100%;
    border: 1px solid #aaa;
    background: linear-gradient(to bottom, #ddd 0, #bbb 100%);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:before {
      content: "≡";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      text-align: center;
      text-indent: 0;
      color: rgb(255, 255, 255);
      font-size: 20px;
      font-weight: 400;
    }
  }
}

.debounce-search {
  width: auto;
  margin-right: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
}

.debounce-div {
  display: inline-flex;
  justify-content: flex-end;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .lngSelect {
    width: 17% !important;
  }
  .h-img {
    width: 80px !important;
    height: 80px !important;
  }
  .col-9 h4 {
    font-size: 25px;
  }
  .header-dp {
    width: 50px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
  .lngSelect {
    width: 35% !important;
    right: 14pc !important;
  }
  .h-img {
    width: 50px !important;
    height: 50px !important;
  }
  .col-9 h4 {
    font-size: 15px;
  }
  #drop-item {
    width: 50% !important;
  }
}

// news page img

#fileDrop {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

#imgUP {
  height: 300px;
  border-radius: 8px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #imgUP {
    height: 200px;
  }
  // mobile header
  // .mobile-header{
  //   width: 30%;
  // }
  // #nav{
  //   display: none;
  // }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
  #imgUP {
    height: 150px;
  }
  .upload-img {
    height: 270px !important;
  }
}

.btn-cancel-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border: "1px solid #000";
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  font-size: 30px;
  -webkit-filter: drop-shadow(0px 0px 15px #000);
  filter: drop-shadow(0px 0px 15px #000);
}

.ah-custom-input input {
  border: "1px solid #D3D3D3" !important;
  padding-left: 10px !important;
  margin-bottom: 10px !important;
}

html {
  overflow-x: hidden;
}

.form-page-content {
  margin-top: 5% !important;
}
.chrome-picker {
  height: 270px;
  width: auto;
  box-sizing: border-box !important;
  position: absolute;
}

.card-reg {
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.08);
}
.row-reg {
  justify-content: center;
}
.auth-reg {
  height: 188px;
}

.btn-skip {
  width: 230%;
  margin-left: -125%;
}
.btn-finish {
  width: 190%;
  margin-left: 25%;
}
.form-control-search {
  width: auto;
}
.test-excel-small {
  display: none;
}
.test-excel-big {
  display: block;
}
.m-r-3 {
  margin-right: 10px;
}
@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .debounce-search {
    width: 180px !important;
    margin-right: 10px !important;
  }
  .test-excel-big {
    display: none;
  }
  .m-r-3 {
    margin-right: 0;
  }
  .test-excel-small {
    display: block;
  }
  .debounce-div {
    display: inline-flex;
    justify-content: flex-start;
  }

  .auth-reg {
    height: 200px;
    width: 100px !important;
  }
  .row-reg {
    padding: 0px;
    justify-content: center;
  }
  .auth-wrapper-2 {
    padding: 30px 0px !important;
  }
  .btn-skip {
    width: 160%;
    margin-left: -55%;
  }
  .btn-finish {
    width: 125%;
    margin-left: 25%;
  }
  .form-page-content {
    margin-top: 115% !important;
  }
  .form-control-search {
    width: 100%;
  }
  .test-download-btn {
    padding: 4px 0px !important;
  }
  .plan-btns {
    text-align: start !important;
    margin-bottom: 3%;
    display: flex;
    padding: inherit;
    margin-left: 4%;
  }
  .position-btns {
    margin-bottom: 15px;
  }
  .chrome-color-pick {
    margin-top: 10%;
    height: 270px;
    width: 280px !important;
    position: inherit;
  }
}

@media only screen and (min-device-width: 1280px) {
  .form-page-content {
    margin-top: 5%;
  }
}
.company-profile {
  height: 270px;
  width: 280px;
  background: #ccc;
  align-self: center;
  box-shadow: 0 5px 11px 0px rgba(69, 90, 100, 0.3);
}

@media only screen and (min-device-width: 1024px) {
  .auth-reg {
    height: 210px;
  }

  .company-profile {
    height: 270px !important;
    width: auto !important;
    background: #ccc;
    align-self: center;
    box-shadow: 0px 5px 11px 0px rgb(69, 90, 100);
  }
  .chrome-color-pick {
    height: 270px;
    width: auto !important;
    box-sizing: border-box !important;
    position: absolute;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .company-profile {
    height: 215px !important;
    width: auto !important;
    background: #ccc;
    align-self: center;
    box-shadow: 0px 5px 11px 0px rgb(69, 90, 100);
  }

  .auth-reg {
    height: 200px;
    width: 150px !important;
  }
  .chrome-color-pick {
    height: 220px;
    width: auto !important;
    box-sizing: border-box !important;
    position: inherit;
  }
}

@media only screen and (min-device-width: 512px) {
  .form-page-content {
    margin-top: 80%;
  }
}
@media only screen and (min-device-width: 540px) {
  .btn-skip {
    width: 225%;
    margin-left: -125%;
  }
  .btn-finish {
    width: 175%;
    margin-left: 33%;
  }
  .form-page-content {
    margin-top: 75%;
  }

  .auth-reg {
    height: 200px;
    width: 150px !important;
  }
}

@media only screen and (min-device-width: 717px) and (max-device-width: 735px) {
  .chrome-color-pick {
    margin-top: 5%;
    height: 270px;
    width: 280px !important;
    position: inherit;
  }
}

.profile-icon {
  font-size: 35px;
  color: #060b14;
  margin-top: 31%;
}
.profile-label {
  font-size: 21px;
  font-family: sans-serif;
}

.outer-card {
  background: #f9f9f9;
  margin: 20px 50px;
}
@media only screen and (min-device-width: 717px) and (max-device-width: 725px) {
  .plan-btns {
    text-align: right;
    position: absolute;
    margin-left: 47%;
  }
}
@media only screen and (min-device-width: 540px) and (max-device-width: 550px) {
  .position-btns {
    margin-bottom: -42px;
  }
  .chrome-color-pick {
    margin-top: 10%;
    height: 270px;
    width: 280px !important;
    position: inherit;
  }
}
@media only screen and (min-device-width: 650px) and (max-device-width: 767px) {
  .col-12.debounce-div {
    width: 60%;
    flex: 0 0 60%;
    justify-content: right;
  }

  .col-12.active-archive-div {
    width: 40%;
    flex: 0 0 40%;
  }
}
.test-download-btn {
  color: rgb(36, 101, 134);
  border: 1px solid rgb(36, 101, 134);
  cursor: pointer;
  background: rgb(255, 255, 255);
  float: right;
  padding: 8px 3px;
  height: 85%;
}
.plan-btns {
  text-align: right;
}
.main-body-card {
  box-shadow: none !important;
}
th:first-child {
  width: 40px !important;
}

.modal-lg {
  max-width: 80%;
}

.imagemain {
  text-align: center;
}

.imagemain:hover {
  box-shadow: rgb(153, 153, 153) 0px 0px 10px;
  text-align: center;
  cursor: pointer;
}

.questionstyle {
  width: 350px;
  height: 25px;
  max-width: 350px;
  white-space: inherit !important;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.fullquestionstyle {
  // position: absolute;
  text-align: justify;
  // width:100%;
  display: block;
  padding: 15px;

  // box-shadow : rgb(153, 153, 153) 0px 0px 10px !important;
}
#bulkupload {
  border-bottom: none;
}

.customtable {
  --bs-table-bg: #fff !important;
  //--bs-table-active-bg: rgba(0,0,0,0.3) !important;
}
.active-tab {
  background-color:#676767 !important;
  color:#fff !important;
  border-color:#606060 !important;  
}

.card-header {
  padding: 1.25rem !important;
}
.form-control{
  height: calc(1.5em + 1.25rem + 2px)!important;
}

.table thead th{
  color: #000!important;
  font-size: 13px!important;
}

.table tbody td{

  color: #373a3c !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > *{
  --bs-table-bg-type:#f9f9f9 !important;
}

.table-striped > tbody > tr:nth-of-type(even) > *{
  --bs-table-bg-type:#fff !important; 
}
.badge-light-primary{
  color: gray!important;
}
.badge{
  padding:0.35em 0.6em!important;
}
.row > .d-md-inline.cb-button{
  width: auto!important;
  padding: 0px !important;
}

body {
  font-size:   14px !important;
  font-weight: 400px !important;
  font-family: "Open Sans",sans-serif;
}

h6 {
  font-weight: 600 !important;
}
#uncontrolled-tab-example-tabpane-group .switch input[type=checkbox]:checked + .cr,
#uncontrolled-tab-example-tabpane-surveys .switch input[type=checkbox]:checked + .cr{
   height: auto;
}

#uncontrolled-tab-example-tabpane-group .switch input[type=checkbox]:not(:checked) + .cr:after,
#uncontrolled-tab-example-tabpane-surveys .switch input[type=checkbox]:not(:checked) + .cr:after{
  margin-top:-10px ;
}

// .btn-primary {
//   &:hover {
//     color: inherit !important; // Keep the original text color
//     background-color: inherit !important; // Keep the original background color
//     border-color: inherit !important; // Keep the original border color
//   }
// }

.btn-check + .btn:hover{
    color: #000;
    background: #f2f2f2;
    border-color: #f2f2f2;

}

.btn-block {

  width: 100% !important;
}

.form-control {
  background-color: (rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;

}

.form-check {
  display: inline-block !important;
}


.toggle-row-explan-plus {
  background-color: #337ab7 ;   
  border:.15em solid #fff ;
  border-radius: 1em ;
  box-shadow: 0 0 .2em #444 ;
  height:1em ;
  width:1em ;
  display: inline-block ;
  font-family: Courier New,Courier,monospace ;
  line-height: 0.75em ;
  color:#fff  ;
  font-weight:600  ;
  text-align:center 
}

.toggle-row-explan-minus {
  background-color: #d33333;
                border:.15em solid #fff;
                border-radius: 1em;
                box-shadow: 0 0 .2em #444;
             
                height:1em;
                width:1em;
                display: inline-block;
                font-family: Courier New,Courier,monospace;
                line-height: 0.75em;
                color:#fff;
                font-weight:600;
                 
                text-align: center
}

.tests-cell {
  text-align: center;
}
.completed-cell {
  text-align: center;
}
.candidate-cell {
  text-align: center;
}
.active_instances-cell {
  text-align: center;
}
.users-cell {
  text-align: center;
}
.form-control {
  
background-color : rgb(232, 240, 254), #465a7e66 !important
}
